import { Box, MediaBox } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import styled from "styled-components"

import CountdownBanner from "areas/header/countdown-banner"

import DesktopNav from "./desktop-nav"
import MobileHeader from "./mobile-header"

const Header = styled(Box).attrs({
  as: "header",
})`
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.theme.zIndices.header};
  ${(props) =>
    props.inCart &&
    props.theme.mediaQueries.tablet`
    position: static;
  `}
`

const DesktopHeaderContainer = styled(MediaBox).attrs((props) => ({
  bg: "white",
  borderBottom: `1px solid ${props.theme.colors.grey[10]}`,
  boxShadow: props.sticky ? "0 6px 10px -6px #bdbdbd" : "none",
  greaterThanOrEqual: "desktop",
}))`
  transition: box-shadow 0.4s;
`

const HeaderContent = ({ microbanner, secondMicroBanner }) => {
  const [sticky, setSticky] = useState(false)
  const headerRef = useRef()
  const rafRef = useRef()
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      rafRef.current = window.requestAnimationFrame(() => {
        if (headerRef.current) {
          setSticky(
            !location.pathname.includes("cart") &&
              window.pageYOffset >
                headerRef.current.getBoundingClientRect().height + 50
          )
        }
      })
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.cancelAnimationFrame(rafRef.current)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Header inCart={location.pathname.includes("cart")} ref={headerRef}>
      <CountdownBanner microbanner={microbanner} />
      <MobileHeader />
      <DesktopHeaderContainer sticky={sticky}>
        <DesktopNav />
      </DesktopHeaderContainer>
      {secondMicroBanner && (
        <CountdownBanner isSecondMicroBanner microbanner={secondMicroBanner} />
      )}
    </Header>
  )
}

HeaderContent.propTypes = {
  microbanner: PropTypes.object,
  secondMicroBanner: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    microbanner: state.sale.microbanner,
    secondMicroBanner: state.sale.second_microbanner,
  }
}

export default connect(mapStateToProps)(HeaderContent)
