import { faSearch } from "@jewlr/pro-regular-svg-icons/faSearch"
import { faTimes } from "@jewlr/pro-regular-svg-icons/faTimes"
import { Button, Flex, FontAwesomeIcon } from "@jewlr/storybook/core"
import { useHandleOutsideClick } from "@jewlr/storybook/helpers"
import loadable from "@loadable/component"
import { FocusScope } from "@react-aria/focus"
import { useFocusWithin, useKeyboard } from "@react-aria/interactions"
import PropTypes from "prop-types"
import React, { useRef, useState, useEffect } from "react"
import Autosuggest from "react-autosuggest"
import { TouchScrollable } from "react-scrolllock-with-horizontal-scroll"
import styled from "styled-components"

import withSearch from "areas/header/hoc/with-search"
import SimpleDeferAfterLoadWrapper from "components/common/simple-defer-load-wrapper"
import { IS_ENG, retry } from "helpers/application"
import { send_search_keyword, setSearchHistory } from "helpers/search"
import withHistory from "hoc/with-history"

const SearchSuggestions = loadable(() =>
  retry(() => import("./search-suggestions"))
)

const SearchBar = styled(Flex)`
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 10px;
  height: var(--desktop-header-height);
  padding: 0 16px;
  position: relative;
  z-index: 3;
  ${(props) => props.theme.mediaQueries.desktop`
    box-shadow: none;
    padding: 0;
  `}
`

const SearchIcon = styled(FontAwesomeIcon).attrs({
  color: "grey.30",
  fontSize: "18px",
})`
  display: block;
  left: 0;
  margin: 0 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => props.theme.mediaQueries.desktop`
    display: none;
  `}
`

const SearchButton = styled(Button).attrs({
  variant: "text",
})`
  display: none;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 14;
  ${(props) => props.theme.mediaQueries.desktop`
    display: block;
    left: 12px;
  `}
`

const ClearButton = styled(Button).attrs({
  variant: "text",
})`
  border-radius: 40px;
  padding: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 15;
  &:focus {
    outline-offset: 0;
  }
  ${(props) => props.theme.mediaQueries.desktop`
    border-radius: 0;
    padding: 0;
    right: 12px;
  `}
`

const CancelButton = styled(Button).attrs({
  variant: "text",
})`
  display: block;
  font-size: 16px;
  ${(props) => props.theme.mediaQueries.desktop`
    display: none;
  `}
`

const StyledForm = styled.form`
  background-color: transparent;
  font-size: 12px;
  position: absolute;
  right: 0px;
  text-align: left;
  top: 0px;
  visibility: visible;
  width: 100%;

  ${(props) => props.theme.mediaQueries.desktop`
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    max-width: 200px;
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    visibility: visible;
  `}

  & .react-autosuggest__container {
    width: 100%;
  }

  & .react-autosuggest__suggestions-container {
    display: ${(props) => (props.$searchShowing ? "block" : "none")};
    position: relative;
    top: auto;
    height: 100vh;
    width: 100%;
    max-height: calc(100vh - 98px);
    overflow-y: auto;
    background-color: white;
    z-index: 2;
    ${(props) => props.theme.mediaQueries.desktop`
      border-radius: 4px;
      border: 1px solid ${props.theme.colors.grey[20]};
      height: auto;
      margin: 0;
      max-height: none;
      overflow-y: none;
      padding: 16px;
      position: absolute;
      right: 0;
      width: 532px;
    `}
  }

  & .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style: none;
    ${(props) => props.theme.mediaQueries.desktop`
      display: grid;
      gap: 16px 12px;
      grid-template-columns: 1fr 1fr 1fr;
    `}
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.colors.dusk};
    ${(props) => props.theme.mediaQueries.desktop`
      background: none;
    `}
  }

  & .react-autosuggest__input {
    -webkit-appearance: none;
  }
`
const StyledInput = styled.input`
  background: ${(props) => props.theme.colors.grey[5]};
  border: none;
  border-radius: 40px;
  box-sizing: content-box;
  color: black;
  font: inherit;
  font-size: 16px;
  height: 40px;
  margin: 9px 0;
  outline: none;
  padding: 0 44px;
  width: 100%;
  &:focus {
    box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary};
    outline: none;
  }

  ${(props) => props.theme.mediaQueries.desktop`
    background: none;
    border-radius: 0;
    font-style: normal;
    font-size: 14px;
    height: auto;
    margin: 0;
    padding-left: 18px;
    padding-right: 0;
    position: relative;
    right: 0;
    transition: width .2s ease-in-out;
    width: 100px;
    &:focus {
      box-shadow: none;
      outline: 2px solid ${props.theme.colors.primary};
      outline-offset: 2px;
    }
    &::-webkit-input-placeholder {
      font-style: normal;
      color: #646464;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
    }
  `}
  ${(props) =>
    props.searchShowing &&
    props.theme.mediaQueries.desktop`
      border: 2px solid ${props.theme.colors.primary};
      border-radius: 34px;
      font-size: 16px;
      line-height: 18px;
      outline-offset: 0;
      padding-bottom: 8px;
      padding-left: 32px;
      padding-right: 24px;
      padding-top: 8px;
      width: 100%;
      &:focus {
        outline: none;
      }
  `}
  }
`

const HeaderSearch = ({
  clearSearch,
  clearSearchSuggestions,
  getSuggestionValue,
  handleCloseSearch,
  isMobile,
  onChange,
  onSuggestionsFetchRequested,
  push,
  renderSuggestion,
  sticky,
  suggestions,
  toggleSearching,
  value,
}) => {
  const formRef = useRef()
  const searchInputRef = useRef()
  const searchButtonRef = useRef()
  const [searchShowing, setSearchShowing] = useState(false)

  const closeSearchBar = () => handleCloseSearch()

  const openSearchBar = () => {
    setSearchShowing(true)
    toggleSearching(false)
  }

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: () => openSearchBar(),
  })
  const { keyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === "Escape") {
        closeSearchBar()
        searchButtonRef.current?.focus()
        e.preventDefault()
      } else if (e.key === "Tab" && e.shiftKey) {
        closeSearchBar()
        e.continuePropagation()
      }
    },
  })
  const { keyboardProps: searchButtonKeyboardProps } = useKeyboard({
    onKeyDown: (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          openSearchBar()
        } else {
          closeSearchBar()
        }
      }
    },
  })

  const handleClearSearch = (e) => {
    e.stopPropagation()
    clearSearch()
    clearSearchSuggestions()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (value.length === 0) {
      return
    }

    setSearchHistory(value)
    clearSearch()
    clearSearchSuggestions()

    let url = encodeURI(`/search?k=${value}`)
    push(url)
  }

  const containerProps = {
    "aria-expanded": searchShowing,
  }

  const inputProps = {
    name: "search",
    onChange: (e) => {
      if (!searchShowing) openSearchBar()
      onChange(e)
    },
    placeholder: `Search ${IS_ENG() ? "Jewlr" : ""}...`,
    ref: searchInputRef,
    type: "search",
    value,
  }

  const shouldRenderSuggestions = (value) => {
    return value.length > 1
  }

  const shouldRenderConfiguredSearch = (query) => {
    return (isMobile || searchShowing) && query.length < 3
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault()
    handleClearSearch(event)
    setSearchHistory(suggestion.query_string)
    send_search_keyword(suggestion.keyword, suggestion.url)
  }

  const renderSuggestionsContainer = ({ containerProps, children, query }) => (
    <TouchScrollable>
      <div {...{ ...containerProps, role: undefined }}>
        <SimpleDeferAfterLoadWrapper>
          <SearchSuggestions
            handleSearchHistorySelected={(e) => handleClearSearch(e)}
            query={query}
            searchShowing={searchShowing}
            shouldRenderConfiguredSearch={shouldRenderConfiguredSearch}
          >
            {children}
          </SearchSuggestions>
        </SimpleDeferAfterLoadWrapper>
      </div>
    </TouchScrollable>
  )

  useEffect(() => {
    if (searchInputRef?.current) {
      searchInputRef.current.focus()
    }
  }, [searchInputRef.current])

  useHandleOutsideClick({
    enabled: searchShowing,
    onOutsideClick: () => closeSearchBar(),
    ref: formRef,
  })

  return (
    <FocusScope contain>
      <StyledForm
        $searchShowing={searchShowing}
        data-cy="header-search-form"
        onSubmit={handleSubmit}
        ref={formRef}
        sticky={sticky}
        {...focusWithinProps}
        {...keyboardProps}
      >
        <Autosuggest
          alwaysRenderSuggestions
          containerProps={containerProps}
          getSuggestionValue={getSuggestionValue}
          id="header-search"
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderInputComponent={(inputProps) => (
            <SearchBar>
              <Flex flex="1" position="relative">
                <StyledInput
                  data-cy="header-search-input"
                  searchShowing={searchShowing}
                  sticky={sticky}
                  {...inputProps}
                />
                {inputProps?.value && (
                  <ClearButton
                    aria-label="Clear search"
                    onClick={(e) => handleClearSearch(e)}
                  >
                    <FontAwesomeIcon
                      fontSize={{ _: "18px", desktop: "12px" }}
                      icon={faTimes}
                    />
                  </ClearButton>
                )}
                <SearchIcon icon={faSearch} />
              </Flex>
              <CancelButton onClick={() => closeSearchBar()}>
                Cancel
              </CancelButton>
            </SearchBar>
          )}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          shouldRenderSuggestions={shouldRenderSuggestions}
          suggestions={suggestions}
        />
        <SearchButton
          aria-label={searchShowing ? "Close search" : "Open search"}
          onClick={() => (searchShowing ? closeSearchBar() : openSearchBar())}
          ref={searchButtonRef}
          {...searchButtonKeyboardProps}
        >
          <FontAwesomeIcon fontSize="18px" icon={faSearch} />
        </SearchButton>
      </StyledForm>
    </FocusScope>
  )
}

HeaderSearch.propTypes = {
  clearSearch: PropTypes.func,
  clearSearchSuggestions: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  handleCloseSearch: PropTypes.func,
  isMobile: PropTypes.bool,
  onChange: PropTypes.func,
  onSuggestionsFetchRequested: PropTypes.func,
  push: PropTypes.func,
  renderSuggestion: PropTypes.func,
  sticky: PropTypes.bool,
  suggestions: PropTypes.array,
  toggleSearching: PropTypes.func,
  value: PropTypes.string,
}

export default withSearch(withHistory(HeaderSearch))
