import { faSearch } from "@jewlr/pro-light-svg-icons/faSearch"
import { Box, Button, Flex, Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"

import {
  clearSearchUserInput,
  clearSearchSuggestions,
} from "app-store-v2/app/actions"
import AccountIcon from "areas/header/dropdowns/account-icon"
import CartIcon from "areas/header/dropdowns/cart-icon"
import { NavIcon } from "areas/header/dropdowns/components"
import withHeader from "areas/header/hoc/with-header"
import WishlistIcon from "areas/header/jewlr/wishlist-icon"

import BugerMenu from "./burger-menu"
import DesktopMenu from "./menu/desktop"
import Search from "./search/search"

const NavBar = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "space-between",
  px: { _: 2, desktop: 3, widescreen: 4 },
})`
  height: var(--desktop-header-height);
`

const HeaderIcon = styled(Box)`
  margin-left: 24px;
  &:first-child {
    margin-left: 0;
  }
`

const DesktopNav = ({
  clearSearch,
  location,
  toggleAccountMenu,
  toggleMenu,
}) => {
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef()
  const searchBtnRef = useRef()
  const restoreSearchFocus = useRef()

  useEffect(() => {
    toggleMenu(true)
    toggleAccountMenu(true)
    setShowSearch(false)
    clearSearch()
  }, [location.pathname])

  useEffect(() => {
    if (!showSearch && restoreSearchFocus.current && searchBtnRef.current) {
      searchBtnRef.current.focus()
      restoreSearchFocus.current = false
    }
  }, [showSearch, searchBtnRef.current])

  const toggleSearchBar = (e) => {
    e.preventDefault()
    setShowSearch(!showSearch)
    toggleMenu(true)
    toggleAccountMenu(true)
  }

  const handleCloseSearch = () => {
    restoreSearchFocus.current = true
    setShowSearch(false)
  }

  return (
    <NavBar>
      <Box flex={{ large: "1 0 auto" }}>
        <Box as={Link} data-lc="home" display="inline-block" to="/">
          <Image
            alt="Jewlr Homepage"
            lazyload={false}
            ratio="701 / 139"
            src={require("images/layout/logo/jewlr-logo.svg")}
            width={{ _: "139px", desktop: "186px", large: "230px" }}
          />
        </Box>
      </Box>
      <DesktopMenu />
      <Flex
        flex={{ _: "1 0 160px", widescreen: "1 0 200px" }}
        justifyContent="flex-end"
        position="relative"
      >
        {showSearch ? (
          <Search
            handleCloseSearch={handleCloseSearch}
            searchInputRef={searchInputRef}
          />
        ) : (
          <>
            <HeaderIcon position="relative">
              <AccountIcon dropdown="account" />
            </HeaderIcon>
            <HeaderIcon position="relative">
              <WishlistIcon dropdown="wishlist" />
            </HeaderIcon>
            <HeaderIcon>
              <Button
                aria-label="Search"
                display="inline-block"
                onClick={toggleSearchBar}
                ref={searchBtnRef}
                variant="text"
              >
                <NavIcon icon={faSearch} />
              </Button>
            </HeaderIcon>
            <HeaderIcon>
              <CartIcon />
            </HeaderIcon>
            <BugerMenu />
          </>
        )}
      </Flex>
    </NavBar>
  )
}

DesktopNav.propTypes = {
  clearSearch: PropTypes.func,
  location: PropTypes.object,
  toggleAccountMenu: PropTypes.func,
  toggleMenu: PropTypes.func,
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSearch() {
      dispatch(clearSearchUserInput())
      dispatch(clearSearchSuggestions())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHeader(withRouter(DesktopNav)))
