import AutosuggestHighlightMatch from "autosuggest-highlight/match"
import AutosuggestHighlightParse from "autosuggest-highlight/parse"
import PropTypes from "prop-types"
import React from "react"

const MatchQuery = ({ keyPrefix, name, query }) => {
  const matches = AutosuggestHighlightMatch(name, query)
  const parts = AutosuggestHighlightParse(name, matches)

  return (
    <>
      {parts.map((part, i) =>
        part.highlight ? (
          <strong key={`${keyPrefix}${i}`}>{part.text}</strong>
        ) : (
          <span key={`${keyPrefix}${i}`}>{part.text}</span>
        )
      )}
    </>
  )
}

MatchQuery.propTypes = {
  keyPrefix: PropTypes.string,
  name: PropTypes.string,
  query: PropTypes.string,
}

export default MatchQuery
