import { faArrowRightLong } from "@jewlr/pro-regular-svg-icons/faArrowRightLong"
import { faCircle } from "@jewlr/pro-solid-svg-icons/faCircle"
import { faDiamond } from "@jewlr/sharp-solid-svg-icons/faDiamond"
import { faSquare } from "@jewlr/sharp-solid-svg-icons/faSquare"
import {
  Box,
  Flex,
  FontAwesomeIcon,
  Image,
  MediaBox,
  Typography,
} from "@jewlr/storybook/core"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { jewlr_t } from "helpers/application"

import Clock from "./clock"

const Container = styled(MediaBox).attrs((props) => ({
  bg: "primary",
  color: "white",
  ...props,
}))`
  height: ${(props) =>
    props.isSecondMicroBanner
      ? "var(--second-microbanner-height)"
      : "var(--countdown-banner-height)"};
`

const Wrapper = styled(Flex).attrs((props) => ({
  alignItems: "center",
  height: "100%",
  justifyContent: props.isSecondMicroBanner
    ? "center"
    : { _: "center", large: "space-between" },
  px: { large: 2 },
  textAlign: "center",
  width: "100%",
  ...props,
}))``

const List = styled.ul`
  align-items: center;
  display: inline-flex;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    align-items: center;
    display: inline-flex;
    padding: 0 6px;
  }
`

const Banner = styled(Typography).attrs((props) => ({
  as: "span",
  fontSize: props.isSecondMicroBanner
    ? { _: "14px", desktop: "16px" }
    : { _: "14px", large: "15px" },
  ...props,
}))`
  line-height: 1;
`

const Delivery = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
`

const textVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
  visible: {
    display: "block",
    opacity: 1,
    transition: { delay: 0.5 },
  },
}

const TextRoller = ({
  color,
  currentIndex,
  id,
  isSecondMicroBanner,
  texts,
}) => {
  return texts.map((text, i) => (
    <motion.div
      animate={currentIndex === i ? "visible" : "hidden"}
      initial="hidden"
      key={`microbanner-${i}-${id}`}
      variants={textVariants}
    >
      {text.type === "countdown" ? (
        <Clock
          color={color}
          isSecondMicroBanner={isSecondMicroBanner}
          text={text}
        />
      ) : text.type === "delivery" ? (
        <Delivery
          as={text.url ? Link : "div"}
          data-lc="countdown-banner"
          to={text.url}
        >
          <Image
            alt={`Fast ${jewlr_t("terms.Shipping")}`}
            height="15px"
            mr="10px"
            src={require("images/layout/header/fast-shipping.svg")}
            width="26px"
          />
          <Banner
            dangerouslySetInnerHTML={{ __html: text.text }}
            textAlign="left"
            {...text.style}
          />
        </Delivery>
      ) : (
        <Box
          as={text.url ? Link : "div"}
          data-lc="countdown-banner"
          to={text.url}
        >
          <Flex alignItems="center">
            {id === "desktop" && (
              <FontAwesomeIcon
                color={color}
                fontSize="12px"
                icon={faDiamond}
                mr="6px"
              />
            )}
            <Banner
              dangerouslySetInnerHTML={{
                __html: text.text,
              }}
              {...text.style}
            />
            {id === "desktop" && (
              <FontAwesomeIcon
                color={color}
                fontSize="16px"
                icon={faArrowRightLong}
                ml="6px"
              />
            )}
          </Flex>
        </Box>
      )}
    </motion.div>
  ))
}

const CountdownBanner = ({ microbanner, isSecondMicroBanner }) => {
  const [currentMobileIndex, setCurrentMobileIndex] = useState(0)
  const [currentDesktopIndex, setCurrentDesktopIndex] = useState(0)

  let desktopTexts = microbanner.desktop_texts
  let mobileTexts = microbanner.mobile_texts

  useEffect(() => {
    if (mobileTexts.length > 0) {
      const mobileInterval = setInterval(() => {
        setCurrentMobileIndex(
          (currentIndex) => (currentIndex + 1) % mobileTexts.length
        )
      }, 5000)
      return () => clearInterval(mobileInterval)
    }
  }, [mobileTexts])

  useEffect(() => {
    if (desktopTexts.length > 0) {
      const desktopInterval = setInterval(() => {
        setCurrentDesktopIndex(
          (currentIndex) => (currentIndex + 1) % desktopTexts.length
        )
      }, 5000)
      return () => clearInterval(desktopInterval)
    }
  }, [desktopTexts])

  const { color } = microbanner.style || {}

  return (
    <>
      {mobileTexts.length > 0 && (
        <Container
          {...microbanner.style}
          isSecondMicroBanner={isSecondMicroBanner}
          lessThan={isSecondMicroBanner ? "desktop" : "large"}
          px={2}
          width="100%"
        >
          <Wrapper isSecondMicroBanner={isSecondMicroBanner}>
            <TextRoller
              color={color}
              currentIndex={currentMobileIndex}
              id="mobile"
              isSecondMicroBanner={isSecondMicroBanner}
              texts={mobileTexts}
            />
          </Wrapper>
        </Container>
      )}
      {(!isSecondMicroBanner || desktopTexts.length > 0) && (
        <Container
          {...microbanner.style}
          greaterThanOrEqual={isSecondMicroBanner ? "desktop" : "large"}
          isSecondMicroBanner={isSecondMicroBanner}
        >
          <Wrapper isSecondMicroBanner={isSecondMicroBanner}>
            {isSecondMicroBanner ? (
              <TextRoller
                color={color}
                currentIndex={currentDesktopIndex}
                id="desktop"
                isSecondMicroBanner
                texts={desktopTexts}
              />
            ) : (
              <>
                <List color={color}>
                  <li>
                    <FontAwesomeIcon
                      fontSize="12px"
                      icon={faDiamond}
                      mr="6px"
                    />
                    <Link to="/info/shipping_information">
                      FREE {jewlr_t("terms.Shipping")}
                    </Link>
                  </li>
                  <li>
                    <FontAwesomeIcon fontSize="10px" icon={faCircle} mr="6px" />
                    <Link to="/info/returns">99-Day Returns</Link>
                  </li>
                  <li>
                    <FontAwesomeIcon fontSize="12px" icon={faSquare} mr="6px" />
                    <Link to="/info/warranty-information">
                      One-Year Warranty
                    </Link>
                  </li>
                </List>
                {desktopTexts.length > 0 && (
                  <Box>
                    <TextRoller
                      color={color}
                      currentIndex={currentDesktopIndex}
                      id="desktop"
                      texts={desktopTexts}
                    />
                  </Box>
                )}
              </>
            )}
          </Wrapper>
        </Container>
      )}
    </>
  )
}

CountdownBanner.propTypes = {
  isSecondMicroBanner: PropTypes.bool,
  microbanner: PropTypes.object,
}

export default CountdownBanner
