import { faAngleRight } from "@jewlr/pro-regular-svg-icons/faAngleRight"
// import { faSparkles } from "@jewlr/pro-regular-svg-icons/faSparkles"
import { Box, Flex, FontAwesomeIcon, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { IS_US } from "helpers/application"
import useCountdown from "helpers/use-countdown"

import Segment from "./segment"

const Countdown = styled(Flex).attrs((props) => ({
  alignItems: "center",
  flexDirection: { _: "column", tablet: "row" },
  fontSize: props.isSecondMicroBanner
    ? { _: "14px", tablet: "16px" }
    : { _: "14px", tablet: "15px" },
  fontWeight: "400",
  justifyContent: "center",
  ...props,
}))``

const SaleMsg = styled(Typography).attrs({
  as: "span",
  fontWeight: "700",
})``

const SegmentDivider = styled(Typography).attrs({
  as: "span",
})`
  margin: 0 4px;
`

// const StyledIcon = styled(FontAwesomeIcon).attrs((props) => ({
//   color: "#FDEA9D",
//   fontSize: { _: "14px", tablet: "13px" },
//   ...props,
// }))``

const Clock = ({ color, isSecondMicroBanner, text }) => {
  let days = null
  let hours = null
  let minutes = null
  let seconds = null

  // if (sl.config.sale_key.includes("2023_holiday_sale")) {
  //   const targetDate = IS_NA()
  //     ? new Date() > Date.UTC(2023, 11, 13, 8)
  //       ? Date.UTC(2023, 11, 14, 8) // Thu 14th 3AM
  //       : Date.UTC(2023, 11, 13, 8) // Wed 13th 3AM
  //     : Date.UTC(2023, 11, 14, 10) // Thu 14th 5AM
  //   const counter = useCountdown(targetDate, true)
  //   days = counter[0]
  //   hours = counter[1]
  //   minutes = counter[2]
  //   seconds = counter[3]
  // }
  const currentDate = Date.now()
  const nextDay = new Date().setHours(24, 0, 0, 0)
  const targetDate =
    IS_US() &&
    currentDate >= Date.UTC(2024, 4, 10, 4) &&
    currentDate <= Date.UTC(2024, 4, 10, 19)
      ? Date.UTC(2024, 4, 10, 19) // Fri 9th 3PM
      : nextDay
  const counter = useCountdown(targetDate)
  hours = counter[0]
  minutes = counter[1]
  seconds = counter[2]

  return (
    <Box as={text.url ? Link : "div"} data-lc="clock" to={text.url}>
      <Flex
        alignItems="center"
        gap={{ _: 2, tablet: "12px" }}
        justifyContent="center"
      >
        {/* <StyledIcon icon={faSparkles} /> */}
        <Countdown isSecondMicroBanner={isSecondMicroBanner} {...text.style}>
          <SaleMsg dangerouslySetInnerHTML={{ __html: text.text }} />
          <FontAwesomeIcon
            color={color}
            fontSize="12px"
            icon={faAngleRight}
            mx="4px"
            style={{ verticalAlign: "middle" }}
          />
          <Flex alignItems="baseline" display="inline-flex">
            {days && (
              <>
                <Segment name="days" segment={days} />
                <span>d</span>
                <SegmentDivider>|</SegmentDivider>
              </>
            )}
            <Segment name="hours" segment={hours} />
            <span>h</span>
            <SegmentDivider>|</SegmentDivider>
            <Segment name="minutes" segment={minutes} />
            <span>m</span>
            <SegmentDivider>|</SegmentDivider>
            <Segment name="seconds" segment={seconds} />
            <span>s</span>
          </Flex>
        </Countdown>
        {/* <StyledIcon flip="horizontal" icon={faSparkles} /> */}
      </Flex>
    </Box>
  )
}

Clock.propTypes = {
  color: PropTypes.string,
  isSecondMicroBanner: PropTypes.bool,
  text: PropTypes.object,
}

export default Clock
