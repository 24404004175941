import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import { setDesktopSubmenu, activateSubmenu } from "app-store-v2/app/actions"
import useNavigation from "helpers/use-navigation"

import { NavButton, NavItem } from "./components"

const InternalMenuItem = ({
  index,
  item,
  showSubmenu,
  submenu,
  updateSubmenu,
}) => {
  const isOpened = showSubmenu && item.name === submenu.name
  const keyboardProps = useNavigation({ direction: "horizontal" })
  const isLink = !!item.url

  return (
    <NavItem key={index} role="none">
      <NavButton
        $isOpened={isOpened}
        data-cy={
          isLink
            ? `header-menu-link-desktop-${index}-${item.name}`
            : `header-menu-top-level-${item.name}-desktop`
        }
        id={item.name}
        onClick={() => {
          if (!isLink) {
            updateSubmenu(item)
          }
        }}
        onMouseEnter={() => updateSubmenu(isLink ? "" : item)}
        role="menuitem"
        {...(isLink
          ? item.external
            ? {
                as: "a",
                color: "inherit",
                "data-lc": "menu",
                href: item.link,
                target: "_blank",
              }
            : {
                "data-lc": "menu",
                forwardedAs: Link,
                to: item.link,
              }
          : {
              "aria-controls": "nav_dropdown_menu",
              "aria-expanded": isOpened,
              "aria-haspopup": true,
            })}
        tabIndex={
          (showSubmenu && submenu.name ? isOpened : index === 0) ? 0 : -1
        }
        {...item.style}
        {...keyboardProps}
      >
        {item.name}
        {!isLink && (
          <FontAwesomeIcon
            color={isOpened ? "primary" : "grey.55"}
            fontSize="8px"
            icon={faChevronDown}
            ml="4px"
          />
        )}
      </NavButton>
    </NavItem>
  )
}

InternalMenuItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  showSubmenu: PropTypes.bool,
  submenu: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  updateSubmenu: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    showSubmenu: state.menu.showSubmenu,
    submenu: state.menu.desktopSubmenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSubmenu: (cat) => {
      dispatch(activateSubmenu())
      dispatch(setDesktopSubmenu(cat))
    },
  }
}

const MenuItem = connect(mapStateToProps, mapDispatchToProps)(InternalMenuItem)

export default MenuItem
