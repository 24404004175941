import { Box, Button } from "@jewlr/storybook/core"
import styled from "styled-components"

export const NavButton = styled(Button).attrs((props) => ({
  color: "blackPrint",
  fontSize: { _: "14px", widescreen: "16px" },
  fontWeight: "500",
  lineHeight: "16px",
  variant: "text",
  ...props,
}))`
  padding: 6px;
  text-transform: uppercase;
  ${(props) =>
    props.$isOpened &&
    `
      color: ${props.theme.colors.primary};
      :before,
      :after {
        position: absolute;
        display: block;
        content: " ";
        border: 8px solid transparent;
        width: 0;
        height: 0;
      }
      :before {
        border-bottom-color: white;
        bottom: 0;
        z-index: 10;
      }
      :after {
        border-bottom-color: ${props.theme.colors.grey[10]};
        bottom: 1px;
        z-index: 9;
      }
  `}
`

export const NavItem = styled(Box).attrs((props) => ({
  as: "li",
  ...props,
}))`
  list-style: none;
  margin: 0;
`
